import React from "react";
import AppContainer from "../AppContainer";
import GiftWithHeartsIcon from "../icons/Gift.svg";
import { navigateTo } from "../../utils/location";
import Card from "../Card";
import styles from "./index.module.scss";

export default ({ T, appStyles, resetShareGift, keyboardOpen }) => {
  const { PageHeader = {} } = appStyles;

  return (
    <AppContainer.Content appStyles={appStyles}>
      <Card appStyles={appStyles} classNames={styles.CardDesktop}>
        <AppContainer.CenteredColumn>
          <h2 style={PageHeader}>{T("Your friend's reward is on its way.")}</h2>
          <h3>{T("Sharing is the best way to use a gift!")}</h3>
          <GiftWithHeartsIcon
            style={{ width: 100, color: appStyles.accentColor }}
          />
          <AppContainer.Footer relativePosition={keyboardOpen}>
            <AppContainer.Footer.Button
              appStyles={appStyles}
              onClick={() => {
                resetShareGift();
                navigateTo("/");
              }}
            >
              {T("Back to home screen")}
            </AppContainer.Footer.Button>
          </AppContainer.Footer>
        </AppContainer.CenteredColumn>
      </Card>
    </AppContainer.Content>
  );
};
